import { FC } from "react"
import toast from "react-hot-toast"
import { IoMdCopy } from "react-icons/io"
import { useCopyToClipboard } from "react-use"

const RedeemedGiftcardDetails: FC<{
  redeemedData: RedeemedInfo
}> = ({ redeemedData }) => {
  const [, copy] = useCopyToClipboard()

  const copyToClipboardHandler = () => {
    if (!redeemedData?.code) return

    copy(redeemedData.code || "")

    toast.success("Copied to clipboard")
  }

  return (
    <div
      data-cy="redeemed-card"
      className="rounded-lg border bg-white p-3 w-full text-sm md:text-base"
    >
      <h2
        data-cy="redeemed-heading"
        className="text-green-500 text-base md:text-lg"
      >
        You recieved your Gift Card
      </h2>

      {redeemedData.faceValue && (
        <p data-cy="redeemed-face-value" className="text-gray-500">
          Card Value:{" "}
          <span className="font-bold">{redeemedData.faceValue}</span>
        </p>
      )}

      {redeemedData.url && (
        <p data-cy="redeemed-url" className="text-gray-500">
          URL: <span className="font-bold break-words">{redeemedData.url}</span>
        </p>
      )}

      {redeemedData.pin && (
        <p data-cy="redeemed-pin" className="text-gray-500">
          Pin: <span className="font-bold">{redeemedData.pin}</span>
        </p>
      )}

      {redeemedData.validityDate && (
        <p data-cy="redeemed-validity-date" className="text-gray-500">
          Validity Date:{" "}
          <span className="font-bold">{redeemedData.validityDate}</span>
        </p>
      )}

      {redeemedData.code && (
        <div
          data-cy="redeemed-code"
          className="flex flex-col md:flex-row md:items-center gap-1"
        >
          <div>Code:</div>
          <div
            onClick={copyToClipboardHandler}
            className="rounded-sm p-1 cursor-pointer select-none flex gap-5 items-center w-full sm:w-max justify-between border bg-gray-100"
            data-cy="voucher-details-modal-copy-button"
          >
            <div data-cy="voucher-details-modal-code">{redeemedData.code}</div>
            <IoMdCopy className="cursor-pointer" size={20} />
          </div>
        </div>
      )}
    </div>
  )
}

export default RedeemedGiftcardDetails
