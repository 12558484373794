import "./Header.scss"
import "../../styles/global/vars.scss"
import { FC } from "react"

interface IHeaderProps {}

// Header should have the navbar which allows the user to change the theme
const Header: FC<IHeaderProps> = () => {
  return (
    <div className="py-2">
      <header className="HeaderStyle Toolbar h-10 bg-red-300">
        <nav className="navstyle">{/** TO DO : Can create a Nav Bar  */}</nav>
      </header>
    </div>
  )
}

export default Header
