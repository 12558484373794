import "./Card.scss"
import { Card, Button, Alert } from "react-bootstrap"
import { FC, useState } from "react"
import Modal from "antd/lib/modal/Modal"
import { Input, Spin } from "antd"
import { useSearchParams } from "react-router-dom"
import axios from "../../lib/axios"
import { useRedeemedInfo } from "../../store"
import { toast } from "react-hot-toast"
import moment from "moment"

interface ICardProps {
  image?: string
  error?: any
  loading?: boolean
  showBuyButton?: boolean
  description?: string
  brandName?: string
  id?: number
  voucherDetails?: VoucherDetails
}

//Display Gift Voucher
const CardView: FC<ICardProps> = ({
  error,
  loading,
  image,
  description,
  brandName,
  id,
  voucherDetails,
  showBuyButton = false,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [securityCode, setSecurityCode] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitError, setSubmitError] = useState("")

  const { setRedeemedInfo, setMessage, setError } = useRedeemedInfo()

  const [params] = useSearchParams()

  const openModal = () => setShowModal(true)

  const closeModal = () => setShowModal(false)

  const buy = () => {
    const securityKey = params.get("securityKey")
    if (!securityKey) return
    setSubmitLoading(true)

    axios
      .post<{ voucher: RedeemedInfo[]; howToUse: string }>(
        "/v2/end-user-purchase",
        {
          productId: id,
        },
        {
          headers: {
            "security-code": securityCode,
            "security-key": securityKey,
          },
        }
      )
      .then((r) => {
        setRedeemedInfo({ ...r.data.voucher[0], howToUse: r.data.howToUse })
        setError("")
        setMessage("Gift Card purchased successfully")
        toast.success("Gift Card purchase successful")
        setShowModal(false)
        window.scrollTo(0, 0)
      })
      .catch((e) => {
        setMessage("")
        setError("")
        setSubmitError(
          e.response?.data?.error ||
            "Unable to redeem the gift card. Please try again."
        )
      })
      .finally(() => {
        setSubmitLoading(false)
      })
  }

  return error ? (
    <div>{error}</div>
  ) : loading ? (
    <div>Loading...</div>
  ) : image && description ? (
    <Card className="CardMain CardStyle col-md-3">
      <div className="">
        <Card.Img
          data-cy="card-image"
          variant="top"
          className="imageView"
          src={image || ""}
        />
      </div>
      <Card.Body>
        {/* <Card.Title>Brand Name</Card.Title> */}
        <Card.Text className="text-xs sm:text-sm">
          <div
            data-cy="card-description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </Card.Text>

        {voucherDetails?.productPrice && (
          <p className="font-bold" data-cy="product-price">
            {voucherDetails?.productCurrency} {voucherDetails?.productPrice}
          </p>
        )}
        {showBuyButton && (
          <Button onClick={openModal} className="w-full">
            Buy
          </Button>
        )}
        {/* Buy modal */}
        <Modal
          data-cy="buy-modal"
          onCancel={closeModal}
          centered
          visible={showModal}
          title={brandName}
          footer={null}
        >
          <Alert data-cy="organization-name-and-expiry" variant="info">
            <div className="text-blue-500">
              This giftcard from{" "}
              <span className="font-bold">
                {voucherDetails?.organizationName}
              </span>{" "}
              will expire on{" "}
              <span className="font-bold">
                {moment(voucherDetails?.expiryDate).format("DD MMM, YYYY")}
              </span>{" "}
              if not claimed.
            </div>
          </Alert>

          <img className="rounded-lg" src={image || ""} alt={brandName} />
          <div className="mt-1 font-bold">
            Please enter the security code to redeem this gift card
          </div>

          <Input
            onChange={(e) => setSecurityCode(e.target.value)}
            value={securityCode}
            placeholder="Security Code"
            className="mt-1"
          />

          <div className="flex items-center gap-2 mt-2">
            <Button
              data-cy="modal-buy-button"
              disabled={submitLoading || !securityCode}
              variant=""
              className="border"
              onClick={buy}
            >
              {submitLoading ? <Spin size="small" /> : "Buy"}
            </Button>
            <Button
              data-cy="modal-cancel-button"
              onClick={closeModal}
              variant=""
              className="border"
            >
              Cancel
            </Button>
          </div>
          {/* error modal */}
          <Modal
            data-cy="error-modal"
            onOk={() => setSubmitError("")}
            onCancel={() => setSubmitError("")}
            cancelButtonProps={{ style: { display: "none" } }}
            centered
            visible={!!submitError}
          >
            <p className="text-red-500 text-lg">
              {submitError.includes("Unauthorized")
                ? "Invalid security code"
                : submitError}
            </p>
          </Modal>
        </Modal>
      </Card.Body>
    </Card>
  ) : null
}

export default CardView
