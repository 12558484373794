import { useEffect, useState } from "react"
import GiftCard from "../Components/GiftCard"
import { Button, Container } from "react-bootstrap"
import {
  State,
  useAlternateProducts,
  useProductDetails,
  useVoucherDetails,
} from "../lib/requests/product"
import CardView from "../Components/Card"
import axios from "../lib/axios"
import { useSearchParams } from "react-router-dom"
import ScreenLoader from "../Components/ScreenLoader"
import { toast } from "react-hot-toast"
import { useAccountDetails, useRedeemedInfo } from "../store"
import RedeemedGiftcardDetails from "../Components/RedeemedGiftcardDetails"
import { removeProtocolFromURL } from "../helpers/removeProtocolFromURL"

const Redeem = () => {
  //Needed to show the list of cards in case the order has been unable to process.
  const [showCardsList, setShowCardsList] = useState(false)
  const [securityCode, setSecurityCode] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)

  const { setRedeemedInfo, error, setError, data, setMessage } =
    useRedeemedInfo()

  const [params] = useSearchParams()

  const website = useAccountDetails((state) => state.website)

  const {
    data: productDetails,
    error: productDetailsError,
    loading: productDetailsLoading,
  } = useProductDetails()

  const {
    data: voucherDetails,
    loading: voucherLoading,
    refetch: refetchVoucherDetails,
    error: voucherError,
  } = useVoucherDetails()

  const {
    data: alternateProducts,
    loading: productsLoading,
    refetch: refetchAlternateBrands,
  } = useAlternateProducts(voucherDetails, productDetails?.message)

  useEffect(() => {
    if (
      voucherDetails &&
      voucherDetails.attemptWithVendor > 2 &&
      voucherDetails.state !== "issued"
    ) {
      setError(
        "Unable to process your order. Please choose a different card below."
      )
    }
  }, [voucherDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [data])

  const showShowMoreCardsButton =
    (voucherDetails &&
      voucherDetails.attemptWithVendor > 2 &&
      !!productDetails) ||
    error === "Product is out of stock at the moment. Please try again."

  const showAlternateCardsList =
    !!alternateProducts?.brands &&
    !!showCardsList &&
    !!productDetails?.data &&
    !!showShowMoreCardsButton

  const noProductsAvailable =
    !productDetails?.data && !alternateProducts?.brands?.length

  const loadingContent =
    productDetailsLoading || productsLoading || voucherLoading

  const buyGiftCard = (id?: number) => {
    const securityKey = params.get("securityKey")
    if (!securityKey) return
    if (!securityCode) return
    setSubmitLoading(true)

    axios
      .post<{ voucher: RedeemedInfo[]; howToUse: string }>(
        "/v2/end-user-purchase",
        {
          productId: id || undefined,
        },
        {
          headers: {
            "security-code": securityCode,
            "security-key": securityKey,
          },
        }
      )
      .then((r) => {
        setRedeemedInfo({ ...r.data.voucher[0], howToUse: r.data.howToUse })

        toast.success("Gift Card purchased successfully")
        setError("")
        setMessage("Gift Card purchased successfully")
        window.scrollTo(0, 0)
      })
      .catch((e) => {
        refetchVoucherDetails()?.then(refetchAlternateBrands)
        setMessage("")
        setError(
          e.response?.data?.error ||
            "Unable to redeem the gift card. Please try again."
        )
      })
      .finally(() => {
        setSubmitLoading(false)
      })
  }

  if (loadingContent) {
    return (
      <ScreenLoader loading children={<div className="min-h-screen"></div>} />
    )
  }

  if (voucherError?.includes("Not Found")) {
    return (
      <div
        data-cy="warning-message"
        className={`flex justify-center items-center font-bold text-2xl h-72 p-10 lg:w-1/2 w-full mx-auto text-center text-red-500`}
      >
        ❌ Invalid security key. Please check the link and try again.
      </div>
    )
  }

  if (voucherError?.includes("expired")) {
    return (
      <div
        data-cy="warning-message"
        className={`flex justify-center items-center font-bold text-2xl h-72 p-10 lg:w-1/2 w-full mx-auto text-center text-red-500`}
      >
        ❌ This voucher has expired
      </div>
    )
  }

  if (voucherDetails?.state === State.Cancelled) {
    return (
      <div
        data-cy="warning-message"
        className="flex justify-center items-center font-bold text-2xl h-72 p-10 lg:w-1/2 w-full mx-auto text-center text-red-500"
      >
        This order has been cancelled.
      </div>
    )
  }

  if (productDetailsError) {
    return (
      <div
        data-cy="warning-message"
        className="flex justify-center items-center font-bold text-2xl h-72 p-10 lg:w-1/2 w-full mx-auto text-center text-red-500"
      >
        {productDetailsError.includes("securityKey is a required")
          ? "Invalid security key. Please check the link and try again."
          : productDetailsError}
      </div>
    )
  }

  return (
    <ScreenLoader loading={submitLoading}>
      <div className="App pb-20">
        {productDetails?.message.includes("success") && !data ? (
          <GiftCard
            loading={productDetailsLoading}
            error={productDetailsError}
            data={productDetails.data}
            securityCode={securityCode}
            setSecurityCode={setSecurityCode}
            buy={buyGiftCard}
            voucherDetails={voucherDetails}
          />
        ) : productDetails?.message.includes("not available") &&
          !data &&
          !noProductsAvailable ? (
          <div className="p-10 text-center max-w-2xl mx-auto">
            <h2 data-cy="not-found-message" className="text-2xl">
              The product you are trying to redeem is not available at the
              moment. Please redeem a different card below instead.
            </h2>
          </div>
        ) : (
          noProductsAvailable &&
          !data && (
            <div className="p-10 text-center max-w-2xl mx-auto">
              <h2 data-cy="not-found-message" className="text-2xl">
                Currently this product is not available. Please contact{" "}
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {removeProtocolFromURL(website || "")}
                </a>{" "}
                with the URL in your browser for support.
              </h2>
            </div>
          )
        )}

        {showShowMoreCardsButton &&
          voucherDetails &&
          voucherDetails.state !== "issued" &&
          !data && (
            <Container className="flex justify-center place-items-center w-full flex-col pt-5">
              <p>
                Your card has failed 3 times, please contact{" "}
                {voucherDetails.supportEmail}. Or you can choose another card
                from below.
              </p>
              <Button
                data-cy="show-more-cards-button"
                onClick={() => setShowCardsList(true)}
                className="w-max"
              >
                Show More Cards
              </Button>
            </Container>
          )}

        {!!data && (
          <div className="flex flex-col max-w-4xl w-full mx-auto px-2 gap-5">
            <RedeemedGiftcardDetails redeemedData={data} />

            {data?.howToUse && (
              <div data-cy="how-to-use">
                <Container>
                  <h2>How to use</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.howToUse || "",
                    }}
                  />
                </Container>
              </div>
            )}
          </div>
        )}

        {((showAlternateCardsList && !data) ||
          (productDetails?.message.includes("not available") && !data)) && (
          <>
            {!productDetails.message.includes("not available") && (
              <span className="col text-center labelStyle my-10 text-lg p-10">
                Sorry that you were not able to redeem the gift card. Here are
                some alternate gift cards that you can redeem instead.
              </span>
            )}
            <div className="my-10">
              <Container className="flex flex-row justify-center flex-wrap gap-2">
                {alternateProducts?.brands.map((card, _idx) => (
                  <div
                    data-cy={`alternate-card-${_idx}`}
                    className="w-full max-w-sm"
                    key={_idx}
                  >
                    <CardView
                      showBuyButton
                      image={card.productImage}
                      description={card.productDescription}
                      brandName={card.brandName}
                      id={card.productId}
                      voucherDetails={voucherDetails}
                    />
                  </div>
                ))}
              </Container>
            </div>
          </>
        )}
      </div>
    </ScreenLoader>
  )
}

export default Redeem
