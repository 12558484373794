import "./Footer.scss"
import { FC, useState } from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import { useAccountDetails } from "../../store"
import { removeProtocolFromURL } from "../../helpers/removeProtocolFromURL"

interface IFooterProps {}

const Footer: FC<IFooterProps> = () => {
  const [showSupportModal, setShowSupportModal] = useState(false)

  const website = useAccountDetails((state) => state.website)

  return (
    <footer className="flex flex-col gap-2 md:flex-row-reverse fixed items-center justify-between border-t-2 border-gray-200 bottom-0 left-0 w-screen bg-white px-10 py-2">
      <div
        onClick={() => setShowSupportModal(true)}
        className="flex items-center gap-1 cursor-pointer"
        data-cy="open-support-modal-button"
      >
        <div>Need help</div>
        <QuestionCircleOutlined className="text-lg" />
      </div>
      <Modal
        visible={showSupportModal}
        footer={null}
        onCancel={() => setShowSupportModal(false)}
        onOk={() => setShowSupportModal(false)}
        title="Need help?"
        data-cy="support-modal"
      >
        If you have trouble redeeming your giftcard, please contact{" "}
        <a href={website} target="_blank" rel="noopener noreferrer">
          {removeProtocolFromURL(website || "")}
        </a>{" "}
        with the URL in your browser for support.
      </Modal>
    </footer>
  )
}

export default Footer
