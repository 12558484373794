import { FC } from "react"
import CardView from "../Card"
import SecurityCard from "../SecurityCard"
import "./Gift.scss"

interface IGiftProps {
  data?: ProductDetails | null
  loading: boolean
  error: any
  securityCode: string
  voucherDetails?: VoucherDetails
  setSecurityCode: (securityKey: string) => void
  buy: (id?: number) => void
}

//Display Gift Voucher
const GiftCard: FC<IGiftProps> = ({
  error,
  loading,
  securityCode,
  setSecurityCode,
  buy,
  data,
  voucherDetails,
}) => {
  return (
    <div className="container GiftRedeemContainer">
      <div className="row">
        <div className="col-lg-6">
          <CardView
            loading={loading}
            error={error}
            image={data?.image || ""}
            description={data?.description || ""}
            voucherDetails={voucherDetails}
          />
        </div>
        <div className="col-lg-6">
          <SecurityCard
            securityCode={securityCode}
            setSecurityCode={setSecurityCode}
            onSubmit={buy}
            voucherDetails={voucherDetails}
          />
        </div>
      </div>
    </div>
  )
}

export default GiftCard
